<template>
    <div class="app-header__navigation ml-auto">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-app-bar-nav-icon
                    aria-label="menu"
                    class="app-header__nav-toggle"
                    v-on="on"
                    @click.stop="drawer = !drawer"
                />
            </template>
            <span>menu</span>
        </v-tooltip>
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            width="auto"
            class="app-header__nav"
        >
            <v-list class="app-header__nav-list">
                <v-list-item
                    v-for="route in pages"
                    :key="route.path"
                    link
                    exact
                    :to="route.path"
                    :class="{ 'app-header__nav-item-child': route.child }"
                    class="app-header__nav-item"
                >
                    <v-list-item-icon class="app-header__nav-icon">
                        <v-icon>{{ route.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="app-header__nav-title">
                            {{ route.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'SiteNavigation',
    data() {
        return {
            drawer: null,
            routes: this.$router.options.routes
        };
    },
    computed: {
        pages() {
            return this.routes.filter(route => !route.hidden);
        }
    }
};
</script>

<style scoped lang="scss">
.app-header {
    &__nav-toggle {
        z-index: zindex(level4);
        .v-icon {
            color: var(--v-primary-base) !important;
        }
        &:focus {
            .v-icon {
                color: #fff !important;
            }
        }
    }
    &__nav {
        height: 100vh !important;
        max-width: 80%;
    }
    &__nav-list {
        margin-top: 78px;
    }
    &__nav-item {
        padding: 0 gutter-size(6);
        #{$interact} {
            &:before {
                opacity: 0.1 !important;
            }
            .v-icon {
                color: var(--v-primary-base);
            }
        }
        &:first-of-type {
            display: none;
        }
        &.v-list-item--active {
            background-color: var(--v-primary-base);
            color: $white;
            pointer-events: none;
            &:before {
                opacity: 0;
            }
            .v-icon {
                color: $white;
            }
        }
    }
    &__nav-item-child {
        padding-left: 40px;
    }
    &__nav-icon {
        margin-right: gutter-size(4) !important;
        min-width: 30px;
    }
    &__nav-title {
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }
    @media only screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
        &__nav-toggle {
            .v-icon {
                font-size: 32px !important;
                height: 32px !important;
                width: 32px !important;
            }
        }
        &__nav-list {
            margin-top: 97px;
        }
    }
}
</style>
